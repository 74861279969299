<template>
  <div class="abontUs">
    <div class="banner">
      <div class="center">
        <div class="title">
          源自欧洲，专注华商贸易16年
        </div>
        <div class="title2">
          全球分公司<span>3</span>家，<br>
          员工<span>30+</span><br>
          服务华商<span>80000+</span>
        </div>
        <div v-if="mapShow">
          <dv-flyline-chart-enhanced :config="Flylineconfig"
            :style="{width:`${mapWidth}px`, height:`${mapHeight}px`}"
            class="chinaMap"
            :dev="true" />
        </div>

        <img src="@/assets/earth.png"
          style="width:64%;opacity:0"
          ref="map">
        <img src="@/assets/earth.png"
          class="phone">
      </div>
    </div>
    <div class="intro">
      <div class="title"><!-- 系统介绍 --></div>
      <div class="content">
        巧数科技成立于2016年，总部位于上海，全球拥有30多名专业员工，
        并在全球设有多个专属服务网点，以满足华商跨区域经营的需求。
        通过与超过80000+家华商的密切合作，我们积累了丰富的服务经验，
        使我们对华商贸易有了更深刻的理解。我们深信专业源于专注，过去的7年里，
        我们一直专注于为华商提供一站式的解决方案。我们服务的范围涵盖了从商品采购、批发、分销、仓储、零售，
        一直到本士化的电商平台，致力于满足华商贸易涉及的所有信息化管理需求。
      </div>
    </div>
    <div class="link">
      <div class="title">联系我们</div>
      <div class="codeBox">
        <div>
          <img src="@/assets/code33.png"
            class="kefu">
          <div class="flagBox">
            <img src="@/assets/IndexFlag1.png"
              class="flag">
            <span>上海（总部）</span>
          </div>
          <div class="address">
            上海市浦东新区<br>御北路385号<br>2幢
          </div>
        </div>
        <div>
          <img src="@/assets/code44.png"
            class="kefu">
          <div class="flagBox">
            <img src="@/assets/IndexFlag1.png"
              class="flag">
            <span>义乌（分部）</span>
          </div>
          <div class="address">
            浙江省义乌市<br> 曙光大厦B座1705
          </div>
        </div>
        <div>
          <img src="@/assets/code55.png"
            class="kefu">
          <div class="flagBox">
            <img src="@/assets/IndexFlag2.png"
              class="flag">
            <span>欧洲</span>
          </div>
          <div class="address">
            匈牙利,<br> Szállás u. 21-10 épület
          </div>
        </div>
        <div>
          <img src="@/assets/code11.png"
            class="kefu">
          <div class="flagBox">
            <img src="@/assets/IndexFlag3.png"
              class="flag">
            <span>拉美</span>
          </div>
          <div class="address">
            智利, 圣地亚哥,<br> Gorbea <br> 2707
          </div>
        </div>
        <div>
          <img src="@/assets/code22.png"
            class="kefu">
          <div class="flagBox">
            <img src="@/assets/IndexFlag4.png"
              class="flag">
            <span>墨西哥</span>
          </div>
          <div class="address">
            广州城 izazaga 151 号
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { points } from '../points'
import { lines } from '../lines'
export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {
      Flylineconfig: {
        // 飞线点
        points: points,
        // 飞线
        lines: lines,
        line: {
          width: 3,
          color: '#2E93FE'
          // orbitColor: '#2E93FE'
        },
        bgImgSrc: require('@/assets/earth.png'),
        k: -7,
        curvature: 8
      },
      timer: null,
      mapShow: false,
      mapWidth: 0,
      mapHeight: 0
    }
  },
  computed: {},
  created() {
    this.timer = setInterval(() => {
      if (this.mapShow) {
        clearInterval(this.timer)
      }
      if (this.$refs.map) {
        this.mapWidth = this.$refs.map.offsetWidth
        this.mapHeight = this.$refs.map.offsetHeight
        this.mapShow = true
      }
    }, 1000)
  },
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
.banner {
  height: 602px;
  background: linear-gradient(to top, #eef6fd, #ffffff);
  .center {
    padding-top: 120px;
    position: relative;
    margin: 0 auto;
    width: 90%;
    height: 100%;
    padding-top: 133p x;
    .title {
      font-size: 36px;
      font-weight: 500;
      color: #092e53;
      line-height: 50px;
      letter-spacing: 1px;
    }
    .title2 {
      font-size: 20px;
      font-weight: 400;
      color: #092e53;
      line-height: 34px;
      letter-spacing: 1px;
      margin-top: 22px;
      span {
        font-size: 24px;
        color: #2d93fe;
      }
    }
  }
}

.chinaMap {
  margin: auto;
  position: absolute;
  bottom: 0;
  right: 5%;
}

.intro,
.link {
  width: 90%;
  margin: 0 auto;
  margin-top: 76px;

  .title {
    font-size: 30px;
    font-weight: 500;
    color: #333333;
    line-height: 42px;
    letter-spacing: 1px;
    margin-bottom: 14px;
  }
  .content {
    font-size: 20px;
    // font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: 1px;
    margin-bottom: 64px;
    span {
      color: #2d93fe;
    }
  }
}
.link {
  padding-bottom: 88px;
}

.codeBox {
  margin-top: 37px;
  text-align: center;
  display: flex;
  justify-content: space-between;

  > div {
    padding-top: 26px;
    // margin-right: 50px;
    width: 18%;
    height: 353px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px #ceddf1;
    border-radius: 16px;
  }
  .kefu {
    width: 142px;
    height: 142px;
  }
  .flagBox {
    margin-top: 18px;
    margin-bottom: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    .flag {
      width: 32px;
      height: 21px;
      margin-right: 13px;
    }
  }
  .address {
    // white-space: nowrap;
  }
}
.phone {
  display: none;
}
@media screen and (max-width: 768px) {
  .banner {
    height: auto;
    .center {
      width: 100%;
      flex-wrap: wrap;
      padding-top: 3rem;
      .chinaMap {
        display: none;
      }
      .title {
        font-size: 2.4rem;
      }
      .intro {
        font-size: 1.4rem;
        width: 100%;
        height: auto;
        line-height: 20px;
      }
      .phone {
        display: block;
      }
      img {
        width: 100%;
      }
    }
  }
  .intro,
  .link {
    margin-top: 2rem;
    width: 100%;
    padding: 1rem;
    .title {
      font-size: 2.4rem;
      margin-top: 2rem;
    }
    .content {
      font-size: 1.6rem;
      margin-bottom: 2px !important;
    }
  }
  .codeBox {
    flex-wrap: wrap;
    justify-content: center !important;
    > div {
      width: 90%;
      margin-bottom: 30px;
    }
  }
}
</style>
