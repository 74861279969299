<template>
  <div class="appDownloadNew">
    <img src="@/assets/appDownloadNewBg.jpg"
      alt="">

    <div class="content">
      <div>
        <h1>批兔兔</h1>
        <h3>不到店里一样下单，从此生意不在守株待兔！</h3>
        <div class="iconBox">
          <i class="iconfont icon-shouji"></i>
          <span>符合您的使用习惯！简单实用！</span>
        </div>
        <div class="iconBox">
          <i class="iconfont icon-huojian"></i>
          <span>简单高效，极速体验的订货！</span>
        </div>
        <div class="iconBox">
          <i class="iconfont icon-liwuhuodong"></i>
          <span>没有隐含费用！就是免费！</span>
        </div>
        <div class="iconBox">
          <i class="iconfont icon-iconfontanquan"></i>
          <span>加密网络！添加商户需邀请码，安全！</span>
        </div>
        <div class="btnBox">
          <div>
            <div>
              <a href="https://ptu2.com/download/ptu2_v2.apk">
                <img src="@/assets/donwloadAnd.png"
                  alt="">
              </a>
            </div>

            <el-popover placement="top"
              width="220"
              trigger="click">
              <img src="@/assets/downAppCode2.png"
                class="downImg">
              <span class="hand"
                slot="reference">二维码下载</span>
            </el-popover>
          </div>
          <div>
            <div>
              <a target="_blank"
                href="https://itunes.apple.com/app/id1435773691">
                <img src="@/assets/donwloadIos.png"
                  alt="">
              </a>
            </div>
            <span>请在App Store搜索“批兔兔”、“PTU2”</span>
          </div>
        </div>
      </div>
      <div class="iphone">
        <img src="@/assets/iphone_6_plus_white_port.png"
          alt="">
        <div>
          <el-carousel height="405px">
            <el-carousel-item v-for="item in 4"
              :key="item">
              <!-- <h3 class="small">{{ item }}</h3> -->
              <img :src="require(`@/assets/banner${item}.png`)"
                alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
.appDownloadNew {
  position: relative;
  background: black;
  height: 100%;
  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
  .content {
    flex-wrap: wrap;
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
      margin-bottom: 10px;
    }
    h3 {
      margin-bottom: 40px;
    }
    .iconBox {
      margin-bottom: 28px;
      font-size: 14px;
      display: flex;
      i {
        margin-right: 30px;
      }
    }
    .btnBox {
      display: flex;
      img {
        width: 240px;
      }
      > div {
        font-size: 12px;
        text-align: center;
        &:first-child {
          margin-right: 20px;
        }
      }
    }
    .iphone {
      position: relative;
      margin-left: 100px;
      > img {
        width: 260px;
      }
      > div {
        position: absolute;
        left: 16px;
        top: 60px;
        width: 229px;
        height: 405px;
        background: white;
      }
    }
  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item {
  img {
    width: 100%;
    height: 100%;
  }
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
/deep/ .el-carousel__indicators {
  display: none;
}

.hand {
  cursor: pointer;
}
.downImg {
  width: 200px;
  height: 200px;
}

@media screen and (max-width: 768px) {
  .content {
    > div {
      &:first-child {
        padding: 30px;
      }
    }
  }
  .btnBox {
    flex-wrap: wrap;
    > div {
      &:first-child {
        margin-bottom: 30px;
      }
    }
  }
  .iphone {
    display: none;
  }
}
</style>
