var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"appDownloadNew"},[_c('img',{attrs:{"src":require("@/assets/appDownloadNewBg.jpg"),"alt":""}}),_c('div',{staticClass:"content"},[_c('div',[_c('h1',[_vm._v("批兔兔")]),_c('h3',[_vm._v("不到店里一样下单，从此生意不在守株待兔！")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"btnBox"},[_c('div',[_vm._m(4),_c('el-popover',{attrs:{"placement":"top","width":"220","trigger":"click"}},[_c('img',{staticClass:"downImg",attrs:{"src":require("@/assets/downAppCode2.png")}}),_c('span',{staticClass:"hand",attrs:{"slot":"reference"},slot:"reference"},[_vm._v("二维码下载")])])],1),_vm._m(5)])]),_c('div',{staticClass:"iphone"},[_c('img',{attrs:{"src":require("@/assets/iphone_6_plus_white_port.png"),"alt":""}}),_c('div',[_c('el-carousel',{attrs:{"height":"405px"}},_vm._l((4),function(item){return _c('el-carousel-item',{key:item},[_c('img',{attrs:{"src":require(`@/assets/banner${item}.png`),"alt":""}})])}),1)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iconBox"},[_c('i',{staticClass:"iconfont icon-shouji"}),_c('span',[_vm._v("符合您的使用习惯！简单实用！")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iconBox"},[_c('i',{staticClass:"iconfont icon-huojian"}),_c('span',[_vm._v("简单高效，极速体验的订货！")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iconBox"},[_c('i',{staticClass:"iconfont icon-liwuhuodong"}),_c('span',[_vm._v("没有隐含费用！就是免费！")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iconBox"},[_c('i',{staticClass:"iconfont icon-iconfontanquan"}),_c('span',[_vm._v("加密网络！添加商户需邀请码，安全！")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',{attrs:{"href":"https://ptu2.com/download/ptu2_v2.apk"}},[_c('img',{attrs:{"src":require("@/assets/donwloadAnd.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('a',{attrs:{"target":"_blank","href":"https://itunes.apple.com/app/id1435773691"}},[_c('img',{attrs:{"src":require("@/assets/donwloadIos.png"),"alt":""}})])]),_c('span',[_vm._v("请在App Store搜索“批兔兔”、“PTU2”")])])
}]

export { render, staticRenderFns }